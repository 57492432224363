.Home-concept {
  display: grid;
  grid-template-columns: 35% 1fr 1fr 1fr 1fr;
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  align-items: center;
}

.Home-concept:hover {
  transform: scale(1.02);
  background-color: #fff;
}

.Home-text {
  color: #202020;
  display: flex;
  align-items: center;
  gap: 5px;
}

.Home-text-image {
  width: 18px;
  height: 18px;
}

.Home-tick {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Home-tick-active {
  background-color: #f5f5f5;
  display: grid;
  justify-content: center;
}

.Home-image {
  width: 20px;
  height: 20px;
  opacity: 0.5;
}
.Home-image-active {
  width: 20px;
  height: 20px;
  opacity: 1;
}
