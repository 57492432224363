.Home-tick-table-gold {
  background-color: #daa520;
}
.Home-tick-table-platinum {
  background-color: #c0c0c0;
}
.Home-tick-table-infinity {
  background-color: #000;
}

.Home-tick-table-text {
  color: white;
  font-weight: bolder;
  font-size: 14px;
  text-align: center;
  align-self: center;
}
