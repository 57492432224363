.App {
  display: flex;
  flex-direction: column;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  min-height: 100vh;
  /*justify-content: center;*/
  align-items: center;
  background-color: #fff;
}

.Benefits-header {
  position: relative;
}

.Benefits-languages {
  position: absolute;
  top: 0;
  right: 0;
}

.Benefits-language-dropdown {
  align-self: flex-end;
  position: relative;
  min-width: 64px;
  background-color: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
}
.Benefits-language-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  padding-right: 32px;
  background-color: transparent;
  border: none;
  color: #000;
  text-transform: uppercase;
}

.Benefits-language-button:hover {
  cursor: pointer;
}

.Benefits-language-button:focus {
  outline: none;
}

.Benefits-language-button-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Benefits-header-image {
  width: 100%;
  height: auto;
  min-height: 100px;
  object-position: top left;
  object-fit: cover;
}

.Benefits-table {
  width: 100%;
  max-width: 1080px;
}

.Table-home {
  display: grid;
  background-color: #f3f3f3;
  grid-template-columns: 35% 1fr 1fr 1fr 1fr;
}
@media (max-width: 600px) {
  .Table-home {
    grid-template-columns: 32% 1fr 1fr 1fr 1fr;
  }
}
.Table-escrito {
  font-weight: bolder;
  font-size: 1.5rem;
  text-align: center;
  align-self: center;
}

@media (max-width: 600px) {
  .Table-escrito {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .Table-text {
    font-size: 0.5rem;
  }
}
.Home-text-image {
  width: 18px;
  height: 18px;
}

.Table-button {
  max-width: 95%;
  background-color: transparent;
  border: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  padding-top: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Table-button-active {
  border: none;
  background-color: #dbdbdb;
  border-radius: 8px;
}

.Table-button:hover {
  transform: scale(1.1);
}

.Table-image {
  width: 64px;
  height: 64px;
}
@media (max-width: 600px) {
  .Table-image {
    width: 50px;
    height: 50px;
  }
}
.Table-button-text {
  font-weight: 700;
  margin: 0;
  padding: 8px;
}

.Home-concept-mini {
  display: grid;
  grid-template-columns: 51.5% 1fr;
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  align-items: center;
}

.Home-concept-mini-text {
  display: flex;
  justify-content: center;
}

.Home-concept-mini-text2 {
  display: grid;
  grid-template-columns: 35% 1fr 1fr;
  justify-content: center;
}

.Home-concept-mini:hover {
  transform: scale(1.02);
  background-color: #fff;
}

.Home-tick {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Final-text {
  font-style: italic;
  font-size: 10px;
  padding-left: 8px;
}
